<template>
  <div class="row">
    <div class="col-12">
      <div class="row row-cols-1 mt-3">
        <!-- <div class="d-flex align-items-center justify-content-center">
          <div class="alert alert-warning w-75 mt-5" role="alert">溫馨提醒：如透過行動裝置進行操作，由於本站對於in-app瀏覽器（如Facebook 或 LINE 內建）支援程度不佳，如遇到資料無法呈現，可建議使用 Google Chrome、Safari 等系統原生或第三方瀏覽器，此外本站因有使用到統計圖表進行資料呈現，建議可將裝置採橫向方式進行操作以便取得較佳的查詢效果</div>
        </div> -->
        <div class="d-flex align-items-center justify-content-center">
          <PriceCalendarSearchFrom @query="initChart"></PriceCalendarSearchFrom>
        </div>
        <template>
          <div class="ad-container">
            <Adsense data-ad-client="ca-pub-2965351057490665" data-ad-slot="5295990485" data-ad-format="auto" data-full-width-responsive="true"> </Adsense>
          </div>
        </template>
        <main class="mt-3 w-100 m-auto" v-if="show_page">
          <div class="alert alert-light w-100 sticky-lg-top" role="alert">
            <ul>
              <!-- <li>目前暫時無法查詢「台北＝小松」、「台北＝花卷」、「台北＝米子」</li> -->
              <li>所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 1 位」的情況下取得</li>
              <li>除非有另外說明，不然票價單位一律都是「單程未稅價，新台幣(TWD)」，票種也一律都是基本票種（也就是不包含任何附加服務項目）</li>
              <li>本站將盡力提供正確之資訊，惟本網站對於所載資料之完整性、即時性和正確性不做任何擔保</li>
            </ul>
            <div class="table-responsive mt-2">
              <table class="table caption-top table-sm table-bordered">
                <caption>
                  價格色塊說明 (單程未稅)
                </caption>
                <tbody>
                  <tr class="">
                    <td class="align-middle" style="vertical-align: baseline; text-align: center">
                      <div class="d-inline-flex align-items-center">
                        <div class="flex-column mt-1 mb-1">
                          <div class="boxItem">
                            <span class="sakura-circle-20x"></span>
                          </div>
                          <div class="line"></div>
                          <!-- <div class="boxItem">特殊價格</div> -->
                          <div class="mt-2" style="word-wrap: nowrap; width: 90px">
                            <span class="fs-6">特殊價格</span>
                          </div>
                        </div>
                      </div>
                      <!-- <span class="sakura-circle-20x"></span>特殊價格 -->
                    </td>
                    <td class="align-middle" style="vertical-align: baseline; text-align: center">
                      <div class="d-inline-flex align-items-center">
                        <div class="flex-column mt-1 mb-1">
                          <div class="boxItem">
                            <span class="green-circle-20x"></span>
                          </div>
                          <div class="line"></div>
                          <!-- <div class="boxItem">特殊價格</div> -->
                          <div class="mt-2" style="word-wrap: nowrap; width: 90px">
                            <span class="fs-6">TWD 1~1,999</span>
                          </div>
                        </div>
                      </div>
                      <!-- <span class="green-circle-20x"></span> -->
                    </td>
                    <td class="align-middle" style="vertical-align: baseline; text-align: center">
                      <div class="d-inline-flex align-items-center">
                        <div class="flex-column mt-1 mb-1">
                          <div class="boxItem">
                            <span class="yellow-circle-20x"></span>
                          </div>
                          <div class="line"></div>
                          <!-- <div class="boxItem">特殊價格</div> -->
                          <div class="mt-2" style="word-wrap: nowrap; width: 90px">
                            <span class="fs-6">TWD 2,000~3,999</span>
                          </div>
                        </div>
                      </div>
                      <!-- <span class="yellow-circle-20x"></span>TWD 2,000~3,999 -->
                    </td>
                    <td class="align-middle" style="vertical-align: baseline; text-align: center">
                      <div class="d-inline-flex align-items-center">
                        <div class="flex-column mt-1 mb-1">
                          <div class="boxItem">
                            <span class="orange-circle-20x"></span>
                          </div>
                          <div class="line"></div>
                          <!-- <div class="boxItem">特殊價格</div> -->
                          <div class="mt-2" style="word-wrap: nowrap; width: 90px">
                            <span class="fs-6">TWD 4,000~6,999</span>
                          </div>
                        </div>
                      </div>
                      <!-- <span class="orange-circle-20x"></span>TWD 4,000~6,999 -->
                    </td>
                    <td class="align-middle" style="vertical-align: baseline; text-align: center">
                      <div class="d-inline-flex align-items-center">
                        <div class="flex-column mt-1 mb-1">
                          <div class="boxItem">
                            <span class="purple-circle-20x"></span>
                          </div>
                          <div class="line"></div>
                          <!-- <div class="boxItem">特殊價格</div> -->
                          <div class="mt-2" style="word-wrap: nowrap; width: 90px">
                            <span class="fs-6">TWD 7,000~9,999</span>
                          </div>
                        </div>
                      </div>
                      <!-- <span class="purple-circle-20x"></span>TWD 7,000~9,999 -->
                    </td>
                    <td class="align-middle" style="vertical-align: baseline; text-align: center">
                      <div class="d-inline-flex align-items-center">
                        <div class="flex-column">
                          <div class="boxItem">
                            <span class="red-circle-20x"></span>
                          </div>
                          <div class="line"></div>
                          <!-- <div class="boxItem">特殊價格</div> -->
                          <div class="mt-2" style="word-wrap: nowrap; width: 90px">
                            <span class="fs-6">TWD 10,000 以上</span>
                          </div>
                        </div>
                      </div>
                      <!-- <span class="red-circle-20x"></span>TWD 10,000 以上 -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div :class="[travel_type === 'OW' ? 'row row-cols-1 row-cols-sm-1 row-cols-md-1 g-4 d-flex align-items-center justify-content-center' : 'row row-cols-1 row-cols-sm-1 row-cols-md-2 g-4']">
            <div class="col" :class="[travel_type === 'OW' ? 'ow_style' : '']">
              <div class="card h-100">
                <div class="card-body">
                  <apexchart ref="initChart1" type="bar" height="250" :options="chartOptions1" :series="series1"></apexchart>
                </div>
                <div class="card-body">
                  <apexchart ref="initChart2" type="bar" height="150" :options="chartOptions2" :series="series1"></apexchart>
                </div>
                <div class="card-body">
                  <figure>
                    <blockquote class="blockquote">
                      <h4 class="card-title">{{ setOWText }} {{ flightNoText }}</h4>
                    </blockquote>
                    <figcaption class="blockquote-footer fs-6">點擊日期上的價格將會自動引導至官網訂位系統</figcaption>
                  </figure>
                  <!-- <h3 class="card-title">{{ departureStationText1 }} 飛 {{ arrivalStationText1 }} {{ flightNoText }}</h3> -->
                  <!-- <h4 class="card-title">{{ setOWText }} {{ flightNoText }} <br/><small class="text-body-secondary fs-5">點擊日期上的價格會自動引導至官網訂位系統</small></h4> -->
                  <FullCalendar :options="calendarOptions1" ref="calendar1" />
                </div>
              </div>
            </div>

            <div class="col" v-if="travel_type === 'RT' || travel_type === 'MULTI'">
              <div class="card h-100">
                <div class="card-body">
                  <apexchart ref="initChart3" type="bar" height="250" :options="chartOptions3" :series="series2"></apexchart>
                </div>
                <div class="card-body">
                  <apexchart ref="initChart4" type="bar" height="150" :options="chartOptions4" :series="series2"></apexchart>
                </div>
                <div class="card-body">
                  <figure>
                    <blockquote class="blockquote">
                      <h4 class="card-title" v-if="travel_type === 'RT'">{{ setRTText }} {{ flightNoText }}</h4>
                      <h4 class="card-title" v-if="travel_type === 'MULTI'">{{ setMULTIText }} {{ flightNoText }}</h4>
                    </blockquote>
                    <figcaption class="blockquote-footer fs-6">點擊日期上的價格將會自動引導至官網訂位系統</figcaption>
                  </figure>
                  <!-- <h3 class="card-title" v-if="travel_type === 'RT'">{{ departureStationText2 }} 飛 {{ arrivalStationText2 }} {{ flightNoText }}</h3> -->
                  <!-- <h4 class="card-title" v-if="travel_type === 'RT'">{{ setRTText }} {{ flightNoText }}</h4> -->
                  <!-- <h3 class="card-title" v-if="travel_type === 'MULTI'">{{ arrivalStationText2 }} 飛 {{ departureStationText2 }} {{ flightNoText }}</h3> -->
                  <!-- <h4 class="card-title" v-if="travel_type === 'MULTI'">{{ setMULTIText }} {{ flightNoText }}</h4> -->
                  <FullCalendar :options="calendarOptions2" ref="calendar2" />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
  import PriceCalendarSearchFrom from "./PriceCalendarSearchFrom.vue"
  import FullCalendar from "@fullcalendar/vue"
  // import { Calendar } from "@fullcalendar/core"
  // import "bootstrap/dist/css/bootstrap.css"
  import bootstrap5Plugin from "@fullcalendar/bootstrap5"
  import multiMonthPlugin from "@fullcalendar/multimonth"
  import axios from "axios"
  import VueScrollTo from "vue-scrollto"
  import moment from "moment-timezone"
  import VueSimpleAlert from "vue-simple-alert"
  import Cookies from "js-cookie"
  import iataCode from "@/mixins/iataCode.js"
  import { mapState } from "pinia"
  import { usePromoStore } from "@/store/main.js"

  export default {
    components: {
      FullCalendar, // make the <FullCalendar> tag available
      PriceCalendarSearchFrom,
    },
    mixins: [iataCode],
    data: function () {
      return {
        show_page: false,
        travel_type: "",
        series1: [],
        series2: [],
        departureStationText1: "",
        arrivalStationText1: "",
        departureStationText2: "",
        arrivalStationText2: "",
        flightNoText: "",
        chartOptions1: {
          chart: {
            id: "chart1",
            // height: 300,
            type: "bar",
            toolbar: {
              show: false,
            },
            events: {
              // dataPointSelection: (event, chartContext, opts) => {
              //   console.log(chartContext, opts)
              //   // const seriesIndex = config.seriesIndex
              //   // const dataPointIndex = config.dataPointIndex
              //   // console.log(opts.config.series[opts.seriesIndex])
              //   // console.log(opts.config.series[opts.seriesIndex].name)
              //   // console.log(opts.config.series[opts.seriesIndex].data[opts.dataPointIndex])
              // },
              click(event, chartContext, opts) {
                const id = opts.config.series[opts.seriesIndex].data[opts.dataPointIndex]["id"]
                VueScrollTo.scrollTo("#" + id, 500, {
                  container: "body",
                  // easing: "ease-in",
                  offset: -130,
                })
                // console.log(opts.config.series[opts.seriesIndex])
                // console.log(opts.config.series[opts.seriesIndex].name)
                // console.log(opts.config.series[opts.seriesIndex].data[opts.dataPointIndex])
              },
            },
          },
          fill: {
            colors: [
              function ({ value }) {
                const priceList = [1299, 1499, 1599, 1699, 1799, 2499, 2099]
                if (priceList.includes(value)) {
                  return "#D8C6BC"
                } else if (value <= 1999) {
                  return "#198754"
                } else if (value >= 2000 && value <= 3999) {
                  return "#ffc107"
                } else if (value >= 4000 && value <= 6999) {
                  return "#fd7e14"
                } else if (value >= 7000 && value <= 9999) {
                  return "#6f42c1"
                } else if (value >= 10000) {
                  return "#dc3545"
                } else {
                  return "#000"
                }
              },
            ],
          },
          // zoom: {
          //   enabled: true,
          //   type: "xy",
          //   // autoScaleYaxis: false,
          //   // zoomedArea: {
          //   //     fill: {
          //   //         color: '#90CAF9',
          //   //         opacity: 0.4
          //   //     },
          //   //     stroke: {
          //   //         color: '#0D47A1',
          //   //         opacity: 0.4,
          //   //         width: 1
          //   //     }
          //   // }
          // },
          plotOptions: {
            bar: {
              borderRadius: 1,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            // formatter: function (val) {
            //   return val + "%"
            // },
            formatter: function (value) {
              if (value != 0) return value
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },

          xaxis: {
            // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            type: "datetime",
            position: "top",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (value) {
                // return val + "%"
                if (value != 0) return value
              },
            },
          },
          title: {
            // text: "Monthly Inflation in Argentina, 2002",
            floating: true,
            offsetY: 330,
            align: "center",
            style: {
              color: "#444",
            },
          },
        },
        chartOptions2: {
          chart: {
            id: "chart2",
            // height: 150,
            width: "100%",
            stacked: false,
            zoom: {
              type: "xy",
              enabled: true,
              autoScaleYaxis: true,
            },
            grid: {
              padding: {
                left: 40,
                right: 20,
              },
            },
            brush: {
              enabled: true,
              target: "chart1",
            },
            selection: {
              enabled: true,
              fill: {
                color: "#ccc",
                opacity: 0.4,
              },
              stroke: {
                color: "#0D47A1",
              },
              // xaxis: {
              //   min: new Date("20 April 2025").getTime(),
              //   max: new Date("29 April 2025").getTime(),
              // },
            },
          },
          fill: {
            colors: [
              function ({ value }) {
                const priceList = [1299, 1499, 1599, 1699, 1799, 2499, 2099]
                if (priceList.includes(value)) {
                  return "#D8C6BC"
                } else if (value <= 1999) {
                  return "#198754"
                } else if (value >= 2000 && value <= 3999) {
                  return "#ffc107"
                } else if (value >= 4000 && value <= 6999) {
                  return "#fd7e14"
                } else if (value >= 7000 && value <= 9999) {
                  return "#6f42c1"
                } else if (value >= 10000) {
                  return "#dc3545"
                } else {
                  return "#000"
                }
              },
            ],
          },
          stroke: {
            width: 0,
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: [0, 1],
            formatter: function (value) {
              if (value != 0) return value
            },
          },
          labels: [],
          xaxis: {
            type: "datetime",
            // tickPlacement: "on",
            title: {
              text: "旅行日期",
              // offsetX: 440,
              offsetY: 8,
            },
          },
          yaxis: {
            // opposite: true,
            title: {
              text: "單程未稅 TWD",
            },
          },
          noData: {
            text: "Loading...",
          },
        },
        chartOptions3: {
          chart: {
            id: "chart3",
            // height: 300,
            type: "bar",
            toolbar: {
              show: false,
            },
            events: {
              // dataPointSelection: (event, chartContext, opts) => {
              //   console.log(chartContext, opts)
              //   // const seriesIndex = config.seriesIndex
              //   // const dataPointIndex = config.dataPointIndex
              //   // console.log(opts.config.series[opts.seriesIndex])
              //   // console.log(opts.config.series[opts.seriesIndex].name)
              //   // console.log(opts.config.series[opts.seriesIndex].data[opts.dataPointIndex])
              // },
              click(event, chartContext, opts) {
                const id = opts.config.series[opts.seriesIndex].data[opts.dataPointIndex]["id"]
                VueScrollTo.scrollTo("#" + id, 500, {
                  container: "body",
                  // easing: "ease-in",
                  offset: -130,
                })
                // console.log(opts.config.series[opts.seriesIndex])
                // console.log(opts.config.series[opts.seriesIndex].name)
                // console.log(opts.config.series[opts.seriesIndex].data[opts.dataPointIndex])
              },
            },
          },
          fill: {
            colors: [
              function ({ value }) {
                const priceList = [1299, 1499, 1599, 1699, 1799, 2499, 2099]
                if (priceList.includes(value)) {
                  return "#D8C6BC"
                } else if (value <= 1999) {
                  return "#198754"
                } else if (value >= 2000 && value <= 3999) {
                  return "#ffc107"
                } else if (value >= 4000 && value <= 6999) {
                  return "#fd7e14"
                } else if (value >= 7000 && value <= 9999) {
                  return "#6f42c1"
                } else if (value >= 10000) {
                  return "#dc3545"
                } else {
                  return "#000"
                }
              },
            ],
          },
          plotOptions: {
            bar: {
              borderRadius: 1,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            // formatter: function (val) {
            //   return val + "%"
            // },
            formatter: function (value) {
              if (value != 0) return value
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },

          xaxis: {
            // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            type: "datetime",
            position: "top",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              // formatter: function (val) {
              //   return val + "%"
              // },
              formatter: function (value) {
                if (value != 0) return value
              },
            },
          },
          title: {
            // text: "Monthly Inflation in Argentina, 2002",
            floating: true,
            offsetY: 330,
            align: "center",
            style: {
              color: "#444",
            },
          },
        },
        chartOptions4: {
          chart: {
            id: "chart4",
            // height: 150,
            width: "100%",
            stacked: false,
            zoom: {
              type: "x",
              enabled: true,
              autoScaleYaxis: true,
            },
            grid: {
              padding: {
                left: 40,
                right: 20,
              },
            },
            brush: {
              enabled: true,
              target: "chart3",
            },
            selection: {
              enabled: true,
              fill: {
                color: "#ccc",
                opacity: 0.4,
              },
              stroke: {
                color: "#0D47A1",
              },
              // xaxis: {
              //   min: new Date("20 April 2025").getTime(),
              //   max: new Date("29 April 2025").getTime(),
              // },
            },
          },
          fill: {
            colors: [
              function ({ value }) {
                const priceList = [1299, 1499, 1599, 1699, 1799, 2499, 2099]
                if (priceList.includes(value)) {
                  return "#D8C6BC"
                } else if (value <= 1999) {
                  return "#198754"
                } else if (value >= 2000 && value <= 3999) {
                  return "#ffc107"
                } else if (value >= 4000 && value <= 6999) {
                  return "#fd7e14"
                } else if (value >= 7000 && value <= 9999) {
                  return "#6f42c1"
                } else if (value >= 10000) {
                  return "#dc3545"
                } else {
                  return "#000"
                }
              },
            ],
          },
          stroke: {
            width: 0,
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: [0, 1],
            formatter: function (value) {
              if (value != 0) return value
            },
          },
          labels: [],
          xaxis: {
            type: "datetime",
            // tickPlacement: "on",
            title: {
              text: "旅行日期",
              // offsetX: 440,
              offsetY: 8,
            },
          },
          yaxis: {
            opposite: true,
            title: {
              text: "單程未稅 TWD",
            },
          },
          noData: {
            text: "Loading...",
          },
        },
        calendarOptions1: {
          // weekends: false, // initial value
          aspectRatio: 0.8,
          // height: "auto",
          locale: "zh-tw",
          initialDate: "2025-10-01",
          // start: "2025-06-01",
          // end: "2026-06-05",
          timeZone: "Asia/Taipei",
          // initialDate: "2025-03-01",
          plugins: [multiMonthPlugin, bootstrap5Plugin],
          themeSystem: "bootstrap5",
          initialView: "multiMonthYear",
          // multiMonthMaxColumns: 3,
          views: {
            multiMonthYear: {
              type: "multiMonth",
              duration: { months: 7 },
              showNonCurrentDates: true,
              dayMaxEventRows: false,
            },
          },
          multiMonthMaxColumns: 1,
          // multiMonthMinWidth: 400,
          headerToolbar: false,
          // validRange: {
          //   start: "2025-03-01",
          //   end: "2026-10-25",
          // },
          eventClick: function (info) {
            // console.log(info)
            // alert("Event: " + info.event.title)
            // alert("Event: " + info.event.extendedProps.xx)
            // alert("Coordinates: " + info.jsEvent.pageX + "," + info.jsEvent.pageY)
            // alert("View: " + info.view.type)

            // change the border color just for fun
            // info.el.style.borderColor = "red"
            // const url = "./go?ref=IT-" + info.event.id
            // Vue.showConfirm(url)
            VueSimpleAlert.confirm("由於資料具有時效性關係，實際價格以官方訂位系統上所查詢為準").then(async () => {
              const url = "./go?ref=IT-" + info.event.id
              window.open(url, "_blank")
            })
          },
          // eventSources: [
          //   {
          //     events: [],
          //   },
          //   // your event source
          //   // {
          //   //   url: `${this.$apiUrl}getminFare`, // use the `url` property
          //   //   method: "POST",
          //   //   extraParams: {
          //   //     departureStation: "TPE",
          //   //     arrivalStation: "FUK",
          //   //   },
          //   //   // color: "yellow", // an option!
          //   //   // textColor: "black", // an option!
          //   //   // className: "text-black",
          //   //   // color: "yellow",
          //   //   // backgroundColor: "red",
          //   //   // textColor: "green",
          //   // },
          //   // any other sources...
          // ],
          events: [],
          // eventDidMount: function (info) {
          //   const tag = '<div class="page" ref="london' + info.event.id + '">' + info.event.id + "</div>"
          //   info.event.setProp("title", tag)
          //   // info.el.title = '<div class="page" ref="london' + info.event.id + '">' + info.event.id + "</div>'"
          //   // var tooltip = new Tooltip(info.el, {
          //   //   title: info.event.extendedProps.description,
          //   //   placement: 'top',
          //   //   trigger: 'hover',
          //   //   container: 'body'
          //   // });
          // },
          eventContent: function (info) {
            return { html: info.event.title }
          },
          // events: "http://localhost:8000/getminFare",
          // events: [
          //   {
          //     title: "event21",
          //     start: "2025-04-01",
          //     allDay: false, // will make the time show
          //     color: "yellow", // an option!
          //     textColor: "black",
          //     displayEventTime: false,
          //   },
          //   {
          //     title: "",
          //     start: "2025-06-05",
          //     end: "2025-06-07",
          //     xx: "IT222",
          //     // allDay: false, // will make the time show
          //     display: "background",
          //   },
          //   // {
          //   //   title  : 'event3',
          //   //   start  : '2010-01-09T12:30:00',
          //   //   allDay : false // will make the time show
          //   // }
          // ],
          // windowResize: function (arg) {
          //   alert("The calendar has adjusted to a window resize. Current view: " + arg.view.type)
          // },
        },
        calendarOptions2: {
          aspectRatio: 0.8,
          // height: "auto",
          locale: "zh-tw",
          initialDate: "2025-10-01",
          // start: "2025-06-01",
          // end: "2026-06-05",
          timeZone: "Asia/Taipei",
          // initialDate: "2025-03-01",
          plugins: [multiMonthPlugin, bootstrap5Plugin],
          themeSystem: "bootstrap5",
          initialView: "multiMonthYear",
          // multiMonthMaxColumns: 3,
          views: {
            multiMonthYear: {
              type: "multiMonth",
              duration: { months: 7 },
              showNonCurrentDates: true,
              dayMaxEventRows: false,
            },
          },
          multiMonthMaxColumns: 1,
          // multiMonthMinWidth: 400,
          headerToolbar: false,
          // validRange: {
          //   start: "2025-03-01",
          //   end: "2026-10-25",
          // },
          eventClick: function (info) {
            //   console.log(info)
            //   alert("Event: " + info.event.title)
            //   alert("Event: " + info.event.extendedProps.xx)
            //   alert("Coordinates: " + info.jsEvent.pageX + "," + info.jsEvent.pageY)
            //   alert("View: " + info.view.type)

            //   // change the border color just for fun
            //   info.el.style.borderColor = "red"
            VueSimpleAlert.confirm("由於資料具有時效性關係，實際價格以官方訂位系統上所查詢為準").then(async () => {
              const url = "./go?ref=IT-" + info.event.id
              window.open(url, "_blank")
            })
          },
          events: [],
          // eventSources: [
          //   {
          //     events: [],
          //   },
          //   // your event source
          //   // {
          //   //   url: `${this.$apiUrl}getminFare`, // use the `url` property
          //   //   method: "POST",
          //   //   extraParams: {
          //   //     departureStation: "FUK",
          //   //     arrivalStation: "TPE",
          //   //   },
          //   //   // color: "yellow", // an option!
          //   //   // textColor: "black", // an option!
          //   //   // className: "text-black",
          //   //   // color: "yellow",
          //   //   // backgroundColor: "red",
          //   //   // textColor: "green",
          //   // },
          //   // any other sources...
          // ],
          eventContent: function (info) {
            return { html: info.event.title }
          },
          // events: "http://localhost:8000/getminFare",
          // events: [
          //   {
          //     title: "event21",
          //     start: "2025-04-01",
          //     allDay: false, // will make the time show
          //     color: "yellow", // an option!
          //     textColor: "black",
          //     displayEventTime: false,
          //   },
          //   {
          //     title: "",
          //     start: "2025-06-05",
          //     end: "2025-06-07",
          //     xx: "IT222",
          //     // allDay: false, // will make the time show
          //     display: "background",
          //   },
          //   // {
          //   //   title  : 'event3',
          //   //   start  : '2010-01-09T12:30:00',
          //   //   allDay : false // will make the time show
          //   // }
          // ],
        },
        // windowResize: function (arg) {
        //   alert("The calendar has adjusted to a window resize. Current view: " + arg.view.type)
        // },
      }
    },
    // created: function () {},
    // updated: async function () {
    //   console.log("totalCost2", this.totalCost)
    // },
    mounted: async function () {
      // const promoInfo = promoInfo()
      const getPromoInfo = usePromoStore()
      // console.log("getPromoInfo2", getPromoInfo.isPromo)
      // console.log("getPromoInfo3", this.totalCost)
      // console.log("totalCost1", this.totalCost)
      if (this.$route.query.ref) {
        // IT-TPEKIX-S25
        const ref = this.$route.query.ref

        // const regex = /SALE|LOW/gi
        // const found = ref.match(regex)

        const isPromo = getPromoInfo.isPromo
        const promoInfo = this.totalCost

        if (isPromo !== true) {
          const tag1 = ref.split("-")[1]
          const departureStation = tag1.substr(0, 3)
          const arrivalStation = tag1.substr(3, 3)
          const dateRange2 = ref.split("-")[2] === "S25" ? "S25" : ref.split("-")[2]
          const picked1 = dateRange2 === "S25" ? "1" : "2"

          const val = {
            selectedItems1: "RT",
            countryName1: departureStation,
            cityName1: arrivalStation,
            countryName2: "",
            cityName2: "",
            picked1: picked1,
            dateRange2: dateRange2,
            picked2: "1",
            flightNo: "",
          }
          this.initChart(val)
        } else {
          // const tag1 = ref.split("-")[1]
          // console.log('promoInfo.content["route_list"]', promoInfo.content["route_list"])
          const departureStation = promoInfo.content["route_list"][0]["country"].split("|")[1]
          const arrivalStation = promoInfo.content["route_list"][0]["city"][0].split("|")[1]
          // const dateRange2 = ref.split("-")[2] === "S25" ? "S25" : ref.split("-")[2]
          const picked1 = "2"

          const val = {
            selectedItems1: "RT",
            countryName1: departureStation,
            cityName1: arrivalStation,
            countryName2: "",
            cityName2: "",
            picked1: picked1,
            dateRange2: `2025-06`,
            picked2: "1",
            flightNo: "",
          }
          this.initChart(val)
        }
        // redirect_url = `/Login?url=/LowFare?ref=${airline}-${tag}`
      }
      if (!Cookies.get("disable_fareCal_hint")) {
        this.$alert("溫馨提醒：如透過行動裝置進行操作，由於本站對於in-app瀏覽器（如Facebook 或 LINE 內建）支援程度不佳，如遇到資料無法呈現，可建議使用 Google Chrome、Safari 等系統原生或第三方瀏覽器，此外本站因有使用到統計圖表進行資料呈現，建議可將裝置採橫向方式進行操作以便取得較佳的查詢效果").then(() => {
          this.$alert("票價日曆僅提供台灣虎航日本，韓國航線為主查詢").then(() => {
            Cookies.set("disable_fareCal_hint", true, { expires: 7, secure: true })
          })
        })
      }
    },
    computed: {
      ...mapState(usePromoStore, ["totalCost"]),
      setOWText: function () {
        return this.getAirportText(this.departureStationText1) + " 前往 " + this.getAirportText(this.arrivalStationText1)
      },

      setRTText: function () {
        return this.getAirportText(this.departureStationText2) + " 前往 " + this.getAirportText(this.arrivalStationText2)
      },

      setMULTIText: function () {
        return this.getAirportText(this.arrivalStationText2) + " 前往 " + this.getAirportText(this.departureStationText2)
      },
    },
    methods: {
      showConfirm(url) {
        this.$alert("由於資料具有時效性關係，實際價格以各航空公司官方訂位系統上所查詢為準").then(() => {
          window.open(url, "_blank")
        })
      },
      setCalendar: function (calendar_id, travel_mode, travel_date) {
        const views1 = calendar_id.getApi().getOption("views")
        // me.calendarOptions1.events = d["eventSources1"]

        if (travel_mode === "1") {
          views1.multiMonthYear.duration.months = 7
          const trave_month = moment().startOf("month").format("YYYY-MM-DD")
          calendar_id.getApi().gotoDate(trave_month)
        }
        if (travel_mode === "2") {
          views1.multiMonthYear.duration.months = 1

          const trave_month = moment(travel_date).startOf("month").format("YYYY-MM-DD")
          calendar_id.getApi().gotoDate(trave_month)
        }
      },
      initData: async function (val) {
        const travel_type = val.selectedItems1

        const departureStation1 = val.countryName1.split("|").length === 1 ? val.countryName1.split("|")[0] : val.countryName1.split("|")[1]
        const arrivalStation1 = val.cityName1.split("|").length === 1 ? val.cityName1.split("|")[0] : val.cityName1.split("|")[1]

        const departureStation2 = val.countryName2.length === 0 ? "" : val.countryName2.split("|")[1]
        const arrivalStation2 = val.cityName2.length === 0 ? "" : val.cityName2.split("|")[1]

        const travel_mode1 = val.picked1 //1: 全年 2: 指定月
        const travel_mode2 = val.dateRange2 //S25 or '2025-05'
        const travel_mode3 = val.picked2 //1: 不指定班次 2: 指定班次

        const travel_flight = val.picked2 === "2" ? "IT" + val.flightNo : ""

        if (travel_type === "OW") {
          return await axios
            .post(
              `${this.$apiUrl}getminFare`,
              {
                departureStation: departureStation1,
                arrivalStation: arrivalStation1,
                travel_type: travel_type,
                travel_mode1: travel_mode1,
                travel_mode2: travel_mode2,
                travel_mode3: travel_mode3,
                travel_flight: travel_flight,
              },
              {
                headers: {
                  Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((response) => {
              return {
                d1: response.data,
                eventSources1: response.data,
              }
            })
        }

        if (travel_type === "RT") {
          const { data: d1 } = await axios.post(
            `${this.$apiUrl}getminFare`,
            {
              departureStation: departureStation1,
              arrivalStation: arrivalStation1,
              travel_type: travel_type,
              travel_mode1: travel_mode1,
              travel_mode2: travel_mode2,
              travel_mode3: travel_mode3,
              travel_flight: travel_flight,
            },
            {
              headers: {
                Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )

          const { data: d2 } = await axios.post(
            `${this.$apiUrl}getminFare`,
            {
              departureStation: arrivalStation1,
              arrivalStation: departureStation1,
              travel_type: travel_type,
              travel_mode1: travel_mode1,
              travel_mode2: travel_mode2,
              travel_mode3: travel_mode3,
              travel_flight: travel_flight,
            },
            {
              headers: {
                Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )

          return {
            d1: d1,
            d2: d2,
            eventSources1: d1,
            eventSources2: d2,
          }
        }

        if (travel_type === "MULTI") {
          const { data: d1 } = await axios.post(
            `${this.$apiUrl}getminFare`,
            {
              departureStation: departureStation1,
              arrivalStation: arrivalStation1,
              travel_type: travel_type,
              travel_mode1: travel_mode1,
              travel_mode2: travel_mode2,
              travel_mode3: travel_mode3,
              travel_flight: travel_flight,
            },
            {
              headers: {
                Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )

          const { data: d2 } = await axios.post(
            `${this.$apiUrl}getminFare`,
            {
              departureStation: arrivalStation2,
              arrivalStation: departureStation2,
              travel_type: travel_type,
              travel_mode1: travel_mode1,
              travel_mode2: travel_mode2,
              travel_mode3: travel_mode3,
              travel_flight: travel_flight,
            },
            {
              headers: {
                Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )

          return {
            d1: d1,
            d2: d2,
            eventSources1: d1,
            eventSources2: d2,
          }
        }
      },
      initChart: async function (val) {
        const me = this
        const travel_type = val.selectedItems1
        const departureStation1 = val.countryName1.split("|").length === 1 ? val.countryName1.split("|")[0] : val.countryName1.split("|")[1]
        const arrivalStation1 = val.cityName1.split("|").length === 1 ? val.cityName1.split("|")[0] : val.cityName1.split("|")[1]

        const departureStation2 = val.countryName2.length === 0 ? "" : val.countryName2.split("|")[1]
        const arrivalStation2 = val.cityName2.length === 0 ? "" : val.cityName2.split("|")[1]
        const travel_mode1 = val.picked1 //1: 全年 2: 指定月
        const travel_mode2 = val.dateRange2 //S25 or '2025-05'
        const travel_mode3 = val.picked2 //1: 不指定班次 2: 指定班次
        const travel_flight = "IT" + val.flightNo

        me.travel_type = travel_type
        me.show_page = true

        me.departureStationText1 = departureStation1
        me.arrivalStationText1 = arrivalStation1

        if (travel_type === "RT") {
          me.departureStationText2 = arrivalStation1
          me.arrivalStationText2 = departureStation1
        }

        if (travel_type === "MULTI") {
          me.departureStationText2 = arrivalStation2
          me.arrivalStationText2 = departureStation2
        }

        if (travel_mode3 === "1") {
          me.flightNoText = "不指定班次"
        }
        if (travel_mode3 === "2") {
          me.flightNoText = "班次 " + travel_flight
        }

        me.initData(val).then((d) => {
          let x = []

          if (d["d1"].length === 0) {
            me.$alert("查無航班資訊", "系統訊息", "error").then(() => {
              // window.location.href = `./FareCal`
            })
          } else {
            for (let i = 0; i < d["d1"].length; i++) {
              const e = d["d1"][i]
              x.push({
                x: e.start,
                y: e.minPrice,
                id: e.id,
              })
            }
            me.$refs.initChart1.updateSeries([
              {
                name: "tigerlight",
                data: x,
              },
            ])

            me.calendarOptions1.events = d["eventSources1"]
            me.setCalendar(me.$refs.calendar1, travel_mode1, travel_mode2)

            me.$refs.initChart2.updateSeries([
              {
                name: "tigerlight",
                data: x,
              },
            ])

            let ixi = 0,
              jxj = d["d1"].length - 1
            if (d["d1"].length > 3) {
              ixi = 3
              if (d["d1"].length > 12) {
                jxj = 12
              }
            }

            me.chartOptions2 = {
              ...me.chartOptions2,
              ...{
                // labels: response.data[2]
                chart: {
                  // brush: {
                  //   enabled: true,
                  //   target: "vuechart-example4",
                  // },
                  selection: {
                    // enabled: true,
                    xaxis: {
                      min: new Date(d["d1"][ixi]["start"]).getTime(),
                      max: new Date(d["d1"][jxj]["start"]).getTime(),
                    },
                    // fill: {
                    //   color: "#ccc",
                    //   opacity: 0.4,
                    // },
                    // stroke: {
                    //   color: "#0D47A1",
                    // },
                  },
                },
                // colors: response.data[1][2],
                // legend: {
                //   itemMargin: {
                //     vertical: 15,
                //   },
                // },
              },
            }
          }

          if (travel_type === "RT" || travel_type === "MULTI") {
            let y = []

            if (d["d2"].length === 0) {
              me.$alert("查無航班資訊，請重新設定查詢條件", "系統訊息", "error").then(() => {
                // window.location.href = `./PriceCalendar`
              })
            } else {
              for (let i = 0; i < d["d2"].length; i++) {
                const e = d["d2"][i]
                y.push({
                  x: e.start,
                  y: e.minPrice,
                  id: e.id,
                })
              }

              me.$refs.initChart3.updateSeries([
                {
                  name: "tigerlight",
                  data: y,
                },
              ])

              me.calendarOptions2.events = d["eventSources2"]
              me.setCalendar(me.$refs.calendar2, travel_mode1, travel_mode2)

              me.$refs.initChart4.updateSeries([
                {
                  name: "tigerlight",
                  data: y,
                },
              ])
              let iyi = 0,
                jyj = d["d2"].length - 1
              if (d["d2"].length > 13) {
                iyi = 13
                if (d["d2"].length > 22) {
                  jyj = 22
                }
              }

              me.chartOptions4 = {
                ...me.chartOptions4,
                ...{
                  // labels: response.data[2]
                  chart: {
                    // brush: {
                    //   enabled: true,
                    //   target: "vuechart-example4",
                    // },
                    selection: {
                      // enabled: true,
                      xaxis: {
                        min: new Date(d["d2"][iyi]["start"]).getTime(),
                        max: new Date(d["d2"][jyj]["start"]).getTime(),
                      },
                      // fill: {
                      //   color: "#ccc",
                      //   opacity: 0.4,
                      // },
                      // stroke: {
                      //   color: "#0D47A1",
                      // },
                    },
                  },
                  // colors: response.data[1][2],
                  // legend: {
                  //   itemMargin: {
                  //     vertical: 15,
                  //   },
                  // },
                },
              }
            }
          }
        })
      },
    },
  }
</script>
<style>
  /* .bg-event-blue-text {
    color: blue;
  } */
  .ow_style {
    width: 800px;
  }
  /* 
  ::marker {
    color: plum;
  } */

  .sakura-circle {
    width: 40px;
    height: 40px;
    background-color: #d8c6bc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 10; */
    /* top: 10; */
  }

  .red-circle {
    width: 40px;
    height: 40px;
    background-color: #dc3545;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 10; */
    /* top: 10; */
  }

  .orange-circle {
    width: 40px;
    height: 40px;
    background-color: #fd7e14;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .yellow-circle {
    width: 40px;
    height: 40px;
    background-color: #ffc107;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .green-circle {
    width: 40px;
    height: 40px;
    background-color: #198754;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .blcak-circle {
    width: 40px;
    height: 40px;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .gray-circle {
    width: 40px;
    height: 40px;
    background-color: #adb5bd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .purple-circle {
    width: 40px;
    height: 40px;
    background-color: #6f42c1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .sakura-circle-20x {
    width: 20px;
    height: 20px;
    background-color: #d8c6bc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 10; */
    /* top: 10; */
  }

  .red-circle-20x {
    width: 20px;
    height: 20px;
    background-color: #dc3545;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 10; */
    /* top: 10; */
  }

  .orange-circle-20x {
    width: 20px;
    height: 20px;
    background-color: #fd7e14;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .yellow-circle-20x {
    width: 20px;
    height: 20px;
    background-color: #ffc107;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .green-circle-20x {
    width: 20px;
    height: 20px;
    background-color: #198754;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .blcak-circle-20x {
    width: 20px;
    height: 20px;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .gray-circle-20x {
    width: 20px;
    height: 20px;
    background-color: #adb5bd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }

  .purple-circle-20x {
    width: 20px;
    height: 20px;
    background-color: #6f42c1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* left: 50px; */
    /* top: 50px; */
  }
</style>
